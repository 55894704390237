import {Component} from 'react'
import {AppLoadedProps} from '.'

export class AppLoaded extends Component<AppLoadedProps> {
  componentDidMount() {
    this.props.host.registerToComponentDidLayout(this.props.appLoaded)
    if (performance?.mark) {
      performance.mark('events-members-widget-loaded')
    }
  }

  render() {
    return null
  }
}
